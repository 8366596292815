import "./../App.css";
import {CompareStartDate} from "./../Methods.js";
import CSVDownloader from "./CSVDownloader.js";

export const ExportTable = ({state}) => {
  let hasUsers;
  const checkForUsers = (eventItem) => {
    hasUsers = Object.values(state.users).filter(item => item.eventID === eventItem.eventID && item.userType === "confirmed").length > 0;
    return hasUsers;
  }
  return (
    <table cellPadding="0" cellSpacing="0">
      <thead>
        <tr>
          <td className="sixty">Event</td>
          <td>Start date</td>
          <td>Actions</td>
        </tr>
      </thead>
      <tbody>
        {Object.values(state.events)
          .sort(CompareStartDate)
          .filter(item => (state.role === "manager" && state.receptionEventID) ? item.eventID.toString() === state.receptionEventID.toString() : item)
          .map((eventItem,eventIndex) =>
            <tr className="user" key={eventIndex}>
              <td><strong>{eventItem.eventName}</strong></td>
              <td><strong>{eventItem.startDate}</strong></td>
              <td>{checkForUsers(eventItem) ? <CSVDownloader state={state} eventID={eventItem.eventID} eventName={eventItem.eventName} /> : <button disabled>No data</button>}</td>
            </tr>
          )}
      </tbody>
    </table>
  )
}
