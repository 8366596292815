import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import {useEffect, useRef, useState} from "react";
import { useParams, useNavigate } from "react-router-dom";
import {db, functions, storage} from ".././firebase.js";
import { httpsCallable } from "firebase/functions";
import { doc, setDoc } from "firebase/firestore";
import LoadingIcons from "react-loading-icons";
import {FirestoreAutoId,MinsToStr,StrToMins} from "./../Methods.js";
import { getDownloadURL, ref } from "firebase/storage";
import {PDF} from './../components/PDF.js';

export const SelfRegisterUserPage = ({state,setState,ShowModal,handleChange,translation}) => {

  const {eventID,activityGroupID,activityID} = useParams();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showWarning, setShowWarning] = useState();
  const [timer, setTimer] =  useState(false); 
  const [logoLoaded, setLogoLoaded] = useState(false);
  const [localLoading, setLocalLoading] = useState(false);
  const [finished, setFinished] = useState(false);
  const [showPDF,setShowPDF] = useState(false);
  const [url, setURL] = useState("");
  const [pdfURL, setPDFURL] = useState("");
  const [userID, setUserID] = useState();
  const eventItem = state.events[eventID];
  const activityGroupItem = state.activityGroups[activityGroupID];
  const activityItem = state.activities[activityID];
  const endTime = activityItem ? MinsToStr(StrToMins(activityItem.duration) + StrToMins(activityItem.startTime)) : null;
  const checkDuplicate = httpsCallable(functions, "checkDuplicateEW");
  // v2
  // const checkDuplicate = httpsCallable(functions, "checkDuplicateEWSecondGen");
  const activityOverviewText = "" + activityItem.activityName + " " + activityItem.startDate.split("-").reverse().join("-") + ", " + activityItem.startTime + translation[eventItem.language].hrs + " - " + endTime + translation[eventItem.language].hrs + ".";
  const canvasReference = useRef(null);
  const [timerId, setTimerId] = useState(null);

  useEffect(() => {
    ViewRegisterUserPage();
    setUserID(FirestoreAutoId());
    startTimer();
  },[])

  const startTimer = () => {
    const id = setTimeout(() => {
      setShow(false);
      setTimer(true);
    }, 120000);
    setTimerId(id);
  }
  const stopTimer = () => {
    clearTimeout(timerId);
    setShow(true);
    setTimer(false);
    setTimerId(null);
  }

  const OnFocus = () => {
    stopTimer();
    // startTimer();
  }

  const OnBlur = () => {
    startTimer();
  }

  const ViewRegisterUserPage = () => {
    if (!eventItem || !activityGroupItem || !activityItem || !activityItem.open || !activityItem.selfReg) {
      setShowWarning(true);
      return;
    }
    setState((prevState) => ({
      ...prevState,
      activityName: activityItem.activityName,
      countryField: eventItem.countryField,
      institutionField: eventItem.institutionField,
      commentField: eventItem.commentField,
      titleField: eventItem.titleField,
      addressField: eventItem.addressField,
      phoneField: eventItem.phoneField,
      countryRequired: eventItem.countryRequired,
      institutionRequired: eventItem.institutionRequired,
      commentRequired: eventItem.commentRequired,
      titleRequired: eventItem.titleRequired,
      addressRequired: eventItem.addressRequired,
      phoneRequired: eventItem.phoneRequired,
      eventLogo: eventItem.eventLogo || null,
      // users: "filler"
    }));
    if (eventItem.eventLogo) {
      const imageRef = ref(storage, 'event-logos/' + eventID + '/' + eventItem.eventLogo);
      // Get the download URL
      getDownloadURL(imageRef)
        .then((url) => {
          setURL(url);
          setLogoLoaded(true);
          setShow(true);
        })
        .catch((error) => {
          // console.log(error);
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
          setLogoLoaded(true);
          setShow(true);
        });
    }
    else {
      setLogoLoaded(true);
      setShow(true);
    }
  }

  const ViewPDF = (activityItem) => {
    if (activityItem.pdf) {
      window.scrollTo(0, 0);
      document.body.classList.add('modal-open');
      const pdfRef = ref(storage, 'activity-pdfs/' + eventID + '/' + activityItem.activityGroupID + '/' + activityItem.activityID + '/' + activityItem.pdf);
      // Get the download URL
      getDownloadURL(pdfRef)
        .then((url) => {
          setPDFURL(url);
          setShowPDF(true);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    }
  }

  const closePDF = () => {
    document.body.classList.remove('modal-open');
    setShowPDF(false)
  }

  const emailCheck = (userType) => {
    setLocalLoading(true);
    // If fields empty return
    if (!state.email || !state.firstName || !state.lastName || !state.gdpr || (!state.phone && state.phoneRequired) || (!state.address && state.addressRequired) || (!state.title && state.titleRequired) || (!state.comment && state.commentRequired) || (!state.institution && state.institutionRequired) || (!state.country && state.countryRequired)) {
      // console.log("empty fields");
      ShowModal("alert", translation[eventItem.language].pleaseComplete);
      setLocalLoading(false);
      return;
    }
    else {
      // Do admin function check for email
      checkDuplicate({
        email: state.email,
        activityID: activityID,
        activityGroupID: activityGroupID,
        activityType: !activityGroupItem.multiReg ? "activityGroupID" : "activityID"
      })
      .then((result) => {
        // If duplicate
        if (result.data._size > 0) {
          ShowModal("alert", translation[eventItem.language].alreadyRegistered,translation[eventItem.language].pleaseNote);
          setLocalLoading(false);
          return;
        }
        else {
          AddUser(eventID,activityGroupID,activityID,userType);
        }
      })
      .catch((error) => {
        // console.log(error);
        setLocalLoading(false);
      })
    }
  }

  const AddUser = async (eventID,activityGroupID,activityID,userType) => {
    const timeStamp = Date.now();
    const userObject = {
      "firstName": state.firstName,
      "lastName": state.lastName,
      "email": state.email,
      "gdpr": state.gdpr || false,
      "optIn": state.optIn || false,
      "emailConfirmation": state.emailConfirmation || false,
      "title": state.title || null,
      "phone": state.phone || null,
      "institution": state.institution || null,
      "address": state.address || null,
      "country": state.country || null,
      "comment": state.comment || null,
      "userID": userID,
      "userType": userType,
      "timeStamp": timeStamp,
      "lastUpdated": timeStamp,
      "eventID": eventID,
      "activityGroupID": activityGroupID,
      "activityID": activityID,
      "role": "user",
      "selfReg": true
    }
    const docRef = doc(db, "users", userID);
    await setDoc(docRef, userObject);
    setFinished(true);
    setLocalLoading(false);
    ShowModal(
      "alert",
      (userType === "confirmed" ? state.emailConfirmation ? translation[eventItem.language].aConfirmation + " " + state.email + "." : translation[eventItem.language].chosenNotTo : state.emailConfirmation ? translation[eventItem.language].addedToWaiting + state.email + "." : translation[eventItem.language].addedToWaitingNoEmail),
      translation[eventItem.language].thankYou,
      activityOverviewText);
    navigate("/self-register/" + eventID + "");
  }

  return (
    <div>
      <button className="secondary" onClick={() => navigate("/self-register/" + eventID + "")}>{translation[eventItem.language].back}</button>
      <canvas ref={canvasReference} style={{display:'none'}}></canvas>
      {!logoLoaded && <div className="loading">
        <LoadingIcons.Oval height="30" width="65" stroke="#292B63" strokeWidth="5" speed={.75} />
      </div>}
      {show && logoLoaded && <section>
        {state.eventLogo && !finished && <img src={url} alt="Event logo" className="eventLogo self" />}
        {!finished &&
          <div>
            {activityItem.ticketsLeft < 1 && <h1>{translation[eventItem.language].registrationFull}</h1>}
            <h3>{activityItem.activityName}</h3>
            {activityItem.pdf && <button className="secondary" onClick={() => ViewPDF(activityItem)}>{translation[eventItem.language].aboutActivity}</button>}
            <p>{activityItem.startDate.split("-").reverse().join("-")} | {activityItem.startTime}{translation[eventItem.language].hrs} - {endTime}{translation[eventItem.language].hrs} ({activityItem.duration.replace(":","h")})</p>
            <p>{activityItem.activityLocation}{activityItem.activityComment && activityItem.activityLocation && " | " }{activityItem.activityComment}</p>
            <form autoComplete="off" onSubmit={(e) => {e.preventDefault(); emailCheck(activityItem.ticketsLeft > 0 ? "confirmed" : "waiting")}}>
              {eventItem.titleField && <FormInputGroup translation={translation} type="title" required={eventItem.titleRequired} title={translation[eventItem.language].title} language={eventItem.language} className={eventItem.titleRequired && !state.title ? "required" : null} value={state.title} handleChange={handleChange} OnFocus={OnFocus} name="title" />}
              <FormInputGroup translation={translation} title={translation[eventItem.language].firstName} type="text" required={true} className={state.firstName ? null : "required"} value={state.firstName} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="firstName" />
              <FormInputGroup translation={translation} title={translation[eventItem.language].lastName}  type="text" required={true} className={state.lastName ? null : "required"} value={state.lastName} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="lastName" />
              <FormInputGroup translation={translation} title={translation[eventItem.language].emailAddress} type="email" required={true} className={state.email ? null : "required"} value={state.email} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="email" />
              {eventItem.phoneField && <FormInputGroup translation={translation} title={translation[eventItem.language].phone} required={eventItem.phoneRequired} type="text" className={eventItem.phoneRequired && !state.phone ? "required" : null} value={state.phone} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="phone" />}
              {eventItem.institutionField && <FormInputGroup translation={translation} required={eventItem.institutionRequired} title={translation[eventItem.language].institution} type="text" className={eventItem.institutionRequired && !state.institution ? "required" : null} value={state.institution} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="institution" />}
              {eventItem.addressField && <FormInputGroup translation={translation} required={eventItem.addressRequired} title={translation[eventItem.language].address} type="textarea" className={eventItem.addressRequired && !state.address ? "required" : null} value={state.address} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="address" />}
              {eventItem.countryField && <FormInputGroup translation={translation} required={eventItem.countryRequired} title={translation[eventItem.language].country} language={eventItem.language} type="country" className={eventItem.countryRequired && !state.country ? "required" : null} value={state.country} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="country" />}
              {eventItem.commentField && <FormInputGroup translation={translation} required={eventItem.commentRequire} title={translation[eventItem.language].comment} type="textarea" className={eventItem.commentRequired && !state.comment ? "required" : null} value={state.comment} handleChange={handleChange} OnFocus={OnFocus} OnBlur={OnBlur} name="comment" />}
              <span className="gdpr">{translation[eventItem.language].gdprText}</span>
              <FormInputGroup translation={translation} title={translation[eventItem.language].privacyAccept} type="checkbox" required={true} className={state.gdpr ? "checkboxGroup" : "checkboxGroup required"} checked={state.gdpr} handleChange={handleChange} name="gdpr" />
              <FormInputGroup translation={translation} title={translation[eventItem.language].contactedByHologic} type="checkbox" className="checkboxGroup" checked={state.optIn} handleChange={handleChange} name="optIn" />
              <FormInputGroup translation={translation} title={translation[eventItem.language].confirmEmail} type="checkbox" className="checkboxGroup" checked={state.emailConfirmation} handleChange={handleChange} name="emailConfirmation" />
              <button disabled={localLoading} type="submit">{activityItem.ticketsLeft > 0 ? translation[eventItem.language].register : translation[eventItem.language].joinWaiting}</button>
            </form>
          </div>
        }
        {localLoading && <div className="loading">
        <LoadingIcons.Oval height="30" width="65" stroke="#292B63" strokeWidth="5" speed={.75} />
      </div>}
        {/* {finished && <h2>Registration complete</h2>} */}
      </section>}
      {showWarning && <section>
        <h1>{translation[eventItem ? eventItem.language :  "english"].pageNotFound}</h1>
        <p>{translation[eventItem ? eventItem.language :  "english"].nowClosed}</p>
      </section>}
      {timer && <section>
        <h2>{translation[eventItem ? eventItem.language :  "english"].timedOut}</h2>
      </section>}
      {showPDF && 
        <div style={{position:'absolute',left:0,right:0,top:0}}>
          <button className="modalHeader" onClick={() => closePDF()}>{translation[eventItem.language].close}</button>
          <PDF url={pdfURL} />
        </div>
      }
    </div>
  )
}
