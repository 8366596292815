import React, { useEffect, useState } from 'react';
import "./../App.css";
import {FormInputGroup, CheckBoxInput} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import {db, storage} from ".././firebase.js";
import LoadingIcons from "react-loading-icons";
import { doc, updateDoc, deleteDoc, query, where, collection, getDocs } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, deleteObject, listAll } from "firebase/storage";

export const EditEventPage = ({state,ShowModal,Back,handleChange,setState,ResetFields}) => {

  const {eventID} = useParams();
  const [deleteCheck, setDeleteCheck] = useState(false);
  const navigate = useNavigate();
  const eventItem = state.events[eventID];
  const [file, setFile] = useState(null);
  const [fileName,setFileName] = useState("");
  const [url, setURL] = useState("");
  const [logoLoaded, setLogoLoaded] = useState(false);
  const eventRef = doc(db, 'events', eventID);
  const activities = Object.values(state.activities).filter(item => item.eventID === eventItem.eventID);
  console.log(activities.length);

  useEffect(() => {
    ViewEditEventPage(eventID);
  },[]);

  const HandleImageAsFile = (e) => {
    if (e.target.files[0]) {
      setFile(e.target.files[0]);
      let str = e.target.files[0].type;
      setFileName(eventID + '.' + str.replace(/(.*)\//g, ''));
    }
  }

  const FieldCheck = () => {
    const startDateComp = new Date(state.startDate);
    const endDateComp = new Date(state.endDate);
    if (!state.eventName || !state.startDate || !state.endDate || !state.timeZone) ShowModal("alert", "Please complete required fields");
    else if (startDateComp > endDateComp) ShowModal("alert", "Event end date cannot be before start date");
    else EditEvent(eventID);
  }

  const DeleteEvent = async () => {
    const groups = query(collection(db, "activityGroups"), where("eventID", "==", eventID));
    const activities = query(collection(db, "activities"), where("eventID", "==", eventID));
    const users = query(collection(db, "users"), where("eventID", "==", eventID));
    // const users = query(collection(db, "users"), where("eventID", "==", eventID), where("role", "==", "user"));
    const queryGroups = await getDocs(groups);
    const queryActivities = await getDocs(activities); 
    const queryUsers = await getDocs(users); 
    // Delete event, groups, activities and users
    deleteDoc(eventRef);
    DeleteMultipleReferences(queryGroups);
    DeleteMultipleReferences(queryActivities);
    DeleteMultipleReferences(queryUsers);
    DeleteEventStorage(`/qr-tickets/${eventID}/`);
    DeleteEventStorage(`/activity-pdfs/${eventID}/`);
    // Delete event logo
    if (eventItem.eventLogo) {
      // Create a reference to the file to delete
      const imageRef = ref(storage, 'event-logos/' + eventID + '/' + eventItem.eventLogo);
      // Delete the file
      deleteObject(imageRef).then(() => {
        // File deleted successfully
      }).catch((error) => {
        // Uh-oh, an error occurred!
      });
    }
    navigate("/");
    ResetFields();
  }

  const DeleteEventStorage = async (path) => {
    const listRef = ref(storage, path);
    // Find all the prefixes and items.
    listAll(listRef)
    .then((res) => {
      res.prefixes.forEach((folderRef) => {
        listAll(folderRef)
        .then((resChild) => {
          resChild.prefixes.forEach((folderChildRef) => {
            listAll(folderChildRef)
            .then((resGrandChild) => {
              resGrandChild.items.forEach((itemRef) => {
                // All the items under listRef.
                deleteObject(itemRef).then(() => {
                  // File deleted successfully
                }).catch((error) => {
                  // Uh-oh, an error occurred!
                });
              });
            }).catch((error) => {
              // Uh-oh, an error occurred!
            });
          });
        }).catch((error) => {
          // Uh-oh, an error occurred!
        });
      });
    }).catch((error) => {
      // Uh-oh, an error occurred!
    });
  }

  const DeleteMultipleReferences = async(reference) => {
    reference.forEach((doc) => {
      deleteDoc(doc.ref);
    });
  }

  const ViewEditEventPage = (eventID) => {
    if (!eventItem) return;
    setState((prevState) => ({
      ...prevState,
      eventName: eventItem.eventName,
      startDate: eventItem.startDate,
      endDate: eventItem.endDate,
      countryField: eventItem.countryField || false,
      institutionField: eventItem.institutionField || false,
      commentField: eventItem.commentField || false,
      titleField: eventItem.titleField || false,
      addressField: eventItem.addressField || false,
      phoneField: eventItem.phoneField || false,
      countryRequired: eventItem.countryRequired || false,
      institutionRequired: eventItem.institutionRequired || false,
      commentRequired: eventItem.commentRequired || false,
      titleRequired: eventItem.titleRequired || false,
      addressRequired: eventItem.addressRequired || false,
      phoneRequired: eventItem.phoneRequired || false,
      eventLogo: eventItem.eventLogo || null,
      language: eventItem.language || "english",
      timeZone: eventItem.timeZone || state.timeZone
    }));
    if (eventItem.eventLogo) {
      const imageRef = ref(storage, 'event-logos/' + eventID + '/' + eventItem.eventLogo);
      // Get the download URL
      getDownloadURL(imageRef)
        .then((url) => {
          setURL(url);
          setLogoLoaded(true);
        })
        .catch((error) => {
          // A full list of error codes is available at
          // https://firebase.google.com/docs/storage/web/handle-errors
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
          setLogoLoaded(true);
        });
    }
    else setLogoLoaded(true);
  }

  const EditEvent = async (eventID) => {
    updateDoc(eventRef,{
      "eventName": state.eventName,
      "startDate": state.startDate,
      "endDate": state.endDate,
      "eventID": eventID,
      "countryField": state.countryField || false,
      "institutionField": state.institutionField || false,
      "commentField": state.commentField || false,
      "titleField": state.titleField || false,
      "addressField": state.addressField || false,
      "phoneField": state.phoneField || false,
      "countryRequired": state.countryRequired || false,
      "institutionRequired": state.institutionRequired || false,
      "commentRequired": state.commentRequired || false,
      "titleRequired": state.titleRequired || false,
      "addressRequired": state.addressRequired || false,
      "phoneRequired": state.phoneRequired || false,
      "eventLogo": file ? `${fileName}` : state.eventLogo || null,
      "timeZone": state.timeZone,
      "language": state.language
    })
    if (file) {
      const path = `/event-logos/${eventID}/${fileName}`;
      const storageRef = ref(storage, path);
      // 'file' comes from the Blob or File API
      uploadBytes(storageRef, file).then((snapshot) => {
        // Upload completed successfully, now we can get the download URL
        getDownloadURL(snapshot.ref).then((downloadURL) => {
          setURL(downloadURL);
        });
      });
    }
    navigate("/");
    ResetFields();
  }

  if (state.role !=="super" && state.role !== "manager") return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      <section>
        <h1>You cannot access this page</h1>
      </section>
    </div>
  )
  if (eventItem) return (
    <div>
      <button className="secondary" onClick={() => Back(true)}>Back</button>
      {!logoLoaded && <div className="loading">
        <LoadingIcons.Oval height="30" width="65" stroke="#292B63" strokeWidth="5" speed={.75} />
      </div>}
      {logoLoaded && <section>
        <h1>Edit event</h1>
        <form onSubmit={(e) => {e.preventDefault(); FieldCheck()}}>
          <div className="fileInput">
            <FormInputGroup type="file" text="image" title="Change event logo" handleChange={HandleImageAsFile} name="eventLogo" />
          </div>
          {url && <div className="fileInput">
            <img src={url} alt="Event logo" className="eventLogo" />
          </div>}
          <FormInputGroup type="textarea" title="Name" required={true} className={!state.eventName ? "required" : null} value={state.eventName} handleChange={handleChange} name="eventName" />
          <FormInputGroup type="date" title="Event start date" required={true} className={!state.startDate ? "required" : null} value={state.startDate} handleChange={handleChange} name="startDate" />
          <FormInputGroup type="date" title="Event end date" required={true} className={!state.endDate ? "required" : null} value={state.endDate} handleChange={handleChange} name="endDate" />
          <FormInputGroup disabled={activities.length > 0} type="timezone" title="Event timezone - locked once you create an activity!" required={true} className={!state.timeZone ? "required" : null} value={state.timeZone} handleChange={handleChange} name="timeZone" />
          <FormInputGroup type="language" title="Language - self-registration only" required={true} className={!state.language ? "required" : null} value={state.language} handleChange={handleChange} name="language" />
          <h3>Set optional registration fields</h3>
          <table cellPadding="0" cellSpacing="0">
            <thead>
              <tr>
                <td>Field name</td>
                <td>Add field</td>
                <td>Required</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Participant title</td>
                <td><CheckBoxInput checked={state.titleField} handleChange={handleChange} name="titleField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.titleField} checked={state.titleRequired} handleChange={handleChange} name="titleRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant phone</td>
                <td><CheckBoxInput checked={state.phoneField} handleChange={handleChange} name="phoneField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.phoneField} checked={state.phoneRequired} handleChange={handleChange} name="phoneRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant institution</td>
                <td><CheckBoxInput checked={state.institutionField} handleChange={handleChange} name="institutionField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.institutionField} checked={state.institutionRequired} handleChange={handleChange} name="institutionRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant address</td>
                <td><CheckBoxInput checked={state.addressField} handleChange={handleChange} name="addressField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.addressField} checked={state.addressRequired} handleChange={handleChange} name="addressRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant country</td>
                <td><CheckBoxInput checked={state.countryField} handleChange={handleChange} name="countryField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.countryField} checked={state.countryRequired} handleChange={handleChange} name="countryRequired" label="Required" /></td>
              </tr>
              <tr>
                <td>Participant comment</td>
                <td><CheckBoxInput checked={state.commentField} handleChange={handleChange} name="commentField" label="Add field" /></td>
                <td><CheckBoxInput disabled={!state.commentField} checked={state.commentRequired} handleChange={handleChange} name="commentRequired" label="Required" /></td>
              </tr>
            </tbody>
          </table>
          <div className='justifyMobile'>
            <button type="submit" className='half'>Save event</button>
            {state.role === "super" && <button className='half edit' onClick={(e) => {e.preventDefault(); setDeleteCheck(true)}}>Delete event</button>}
          </div>
        </form>
      </section>}
      {deleteCheck && <div className="modal">
        <div>
          <p>Are you sure? This will delete any activity groups, activities and participants as well.</p>
          <button onClick={() => { DeleteEvent(); setDeleteCheck(false)}}>OK</button>
          <button onClick={() => setDeleteCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
  else return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <div>
        <h1>Page not found</h1>
        <p>Event does not exist</p>
        </div>
      </section>
    </div>
  );
}
