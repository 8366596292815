import React, { useEffect, useState } from 'react';
import "./../App.css";
import {FormInputGroup} from "./../components/FormInputGroup.js";
import { useParams, useNavigate } from "react-router-dom";
import {MinsToStr,StrToMins} from "./../Methods.js";
import {functions} from "./../firebase.js";
import { httpsCallable } from "firebase/functions";

export const EmailActivityPage = ({state,handleChange,Back,ResetFields,ShowModal}) => {

  const { eventID, activityGroupID, activityID } = useParams();
  const [sendCheck, setSendCheck] = useState(false);
  const [waitingList, setWaitingList] = useState();
  const [waitingEmpty, setWaitingEmpty] = useState(false);
  const [regList, setRegList] = useState();
  let navigate = useNavigate();
  const emailAdmin = httpsCallable(functions, "emailMultiEW");
  // v2
  // const emailAdmin = httpsCallable(functions, "emailMultiEWSecondGen");

  useEffect(() => {
    const toReg = Object.values(state.users).filter(item => item.activityID === activityID).filter(item => item.emailConfirmation === true).filter(item => item.userType === "confirmed");
    const toWait = Object.values(state.users).filter(item => item.activityID === activityID).filter(item => item.emailConfirmation === true).filter(item => item.userType === "waiting");
    toWait.length > 0 ? setWaitingList(toWait) : setWaitingEmpty(true);
    setRegList(toReg);
  },[]);

  const FieldCheck = () => {
    if (!state.emailSubject || !state.emailBody) ShowModal("alert", "Please complete all required fields");
    else if (state.sendType !== "web") SendFromMyEmail();
    else setSendCheck(true);
  }

  const SendFromMyEmail = () => {
    const toEmail = [];
    const toPeople = state.sendEmail === "confirmed" ? regList : waitingList;
    toPeople.forEach(element => {
      toEmail.push(element.email);
    })
    const toOutlook = toEmail.join(";");
    state.sendType === "email" ? window.location = 'mailto:' + toEmail + '?subject=' + state.emailSubject + '&body=' + state.emailBody : window.location = 'mailto:' + toOutlook + '?subject=' + state.emailSubject + '&body=' + state.emailBody;
  }

  const AdhocEmail = (eventID, activityGroupID, activityID) => {
    const toPeople = state.sendEmail === "confirmed" ? regList : waitingList;
    const eventItem = state.events[eventID];
    const activity = state.activities[activityID];
    const activityEndTime = MinsToStr(StrToMins(activity.duration) + StrToMins(activity.startTime));
    const reverseStartDate = activity.startDate.split("-").reverse().join("-");
    emailAdmin({
      language: eventItem.language,
      toPeople: toPeople,
      eventName: eventItem.eventName,
      eventLogo: eventItem.eventLogo,
      activity: activity,
      subject: state.emailSubject,
      message: state.emailBody,
      activityEndTime: activityEndTime,
      reverseStartDate: reverseStartDate,
      emailType: 'adhoc'
    })
    .then((result) => {
      // console.log(result);
    })
    .catch((error) => {
      // console.log(error);
    })
    // Finish
    navigate("/activity-overview/" + eventID + "/" + activityGroupID + "/" + activityID);
    ResetFields();
  }

  return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <h1>Email participants</h1>
        <form onSubmit={(e) => {e.preventDefault(); FieldCheck()}}>
          <FormInputGroup required={true} type="sendEmail" title="Send email" className={!state.sendEmail ? "required" : null} waitingEmpty={waitingEmpty} value={state.sendEmail} handleChange={handleChange} name="sendEmail" />
          <FormInputGroup required={true} type="sendType" title="Send type" className={!state.sendType ? "required" : null} value={state.sendType} handleChange={handleChange} name="sendType" />
          <FormInputGroup required={true} className={!state.emailSubject ? "required" : null} type="textarea" value={state.emailSubject} handleChange={handleChange} name="emailSubject" title="Email subject" />
          <FormInputGroup required={true} className={!state.emailBody ? "required" : null} type="textarea" handleChange={handleChange} name="emailBody" value={state.emailBody} title="Email body" />
          <button type="submit">Send email</button>
        </form>
      </section>
      {sendCheck && <div className="modal">
        <div>
          <p>Are you sure? This will send an email to all {state.sendEmail === "confirmed" ? "confirmed" : "waiting"} participants of this activity</p>
          <button onClick={() => {AdhocEmail(eventID, activityGroupID, activityID); ShowModal("alert", "Email sent!")}}>OK</button>
          <button onClick={() => setSendCheck(false)}>Cancel</button>
        </div>
      </div>}
    </div>
  )
}
