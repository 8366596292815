import { useState }  from "react";
import "./../App.css";
import {ConvertTime, CompareTimeStamp, Capitalize} from "./../Methods.js";
import { Link } from "react-router-dom";

export const SearchUsersPage = ({Back,state}) => {
  const [search,setSearch] = useState("");
  const eventUsers = Object.values(state.users).filter((item) => item.eventID === state.receptionEventID && item.role === "user").sort(CompareTimeStamp);
  return (
    <div>
      <button className="secondary" onClick={() => Back()}>Back</button>
      <section>
        <h1>Participant search</h1>
        <input className="mobileMargin" placeholder="Search by name or email" type="text" onChange={event => setSearch(event.target.value)} />
        <table cellPadding="0" cellSpacing="0" className="userSearch">
          <thead>
            <tr>
              <td>First name</td>
              <td>Last name</td>
              <td>Email</td>
              <td>Activity</td>
              <td>Status</td>
              <td>Registered date</td>
            </tr>
          </thead>
          <tbody>
            {eventUsers.length > 0 && eventUsers.map((registeredItem, registeredIndex) => {
              if (registeredItem.firstName.toLowerCase().includes(search.toLowerCase()) || registeredItem.lastName.toLowerCase().includes(search.toLowerCase()) || registeredItem.email.toLowerCase().includes(search.toLowerCase())) return (
                <tr key={registeredIndex}>
                  <td><strong>{registeredItem.firstName}</strong></td>
                  <td><strong>{registeredItem.lastName}</strong></td>
                  <td>{registeredItem.email}</td>
                  <td><Link to={"/activity-overview/" + registeredItem.eventID + "/" + registeredItem.activityGroupID + "/" + registeredItem.activityID}>{state.activities[registeredItem.activityID].activityName}</Link></td>
                  <td>{Capitalize(registeredItem.userType)}</td>
                  <td>{ConvertTime(registeredItem.timeStamp)}</td>
                </tr>
              )
              return null;
            })}
            {eventUsers.length === 0 &&
              <tr>
                <td>No participants</td>
                <td/>
                <td/>
                <td/>
                <td/>
              </tr>
            }
          </tbody>
        </table>
      </section>
    </div>
  )
}
