import React,{ useEffect } from 'react';
import {ConvertTime, Capitalize} from "./../Methods.js";
import { useCSVDownloader } from 'react-papaparse';

export default function CSVDownloader({eventID,state}) {
  const { CSVDownloader, Type } = useCSVDownloader();
  let data = [];
  useEffect(() => {
    // Object.values(state.events).filter(item => eventID ? item.eventID === eventID : item).forEach(eventItem => {
      Object.values(state.users).filter(item => eventID ? item.eventID === eventID && item.role === "user" : item.role === "user").forEach(registeredItem => {
        data.push({
          'First name': registeredItem.firstName,
          'Last name': registeredItem.lastName,
          'Email address': registeredItem.email,
          'Title': registeredItem.title,
          'Phone': registeredItem.phone,
          'Institution': registeredItem.institution,
          'Address': registeredItem.address,
          'Country': registeredItem.country,
          'Comment': registeredItem.comment,
          'Event': state.events[registeredItem.eventID].eventName,
          'Group': state.activityGroups[registeredItem.activityGroupID].activityGroupName,
          'Activity': state.activities[registeredItem.activityID].activityName,
          'Date': state.activities[registeredItem.activityID].startDate,
          'Start time': state.activities[registeredItem.activityID].startTime,
          'Checked-in': registeredItem.checkedIn ? "Yes" : "No",
          'Status': Capitalize(registeredItem.userType),
          'Privacy Policy accepted': registeredItem.gdpr ? "Yes" : "No",
          'Opt-In to email': registeredItem.optIn ? "Yes" : "No",
          'Receive activity email': registeredItem.emailConfirmation ? "Yes" : "No",
          'Registered date': ConvertTime(registeredItem.timeStamp),
          'Last updated': ConvertTime(registeredItem.lastUpdated)
        });
      })
    // })
    // console.log(data);
  },[])
  return (
    <CSVDownloader
      type={Type.Button}
      filename={eventID ? "event-data" : "all-workshop-data"}
      bom={true}
      config={
        {
          delimiter: ',',
        }
      }
      data={() => {
        return data
      }}
    >
      {eventID ? "Export data" : "Export all participant data"}
    </CSVDownloader>
  );
}
